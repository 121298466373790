const baseURL = (window.location.hostname === 'localhost') ? 'http://localhost:8098' : window.location.protocol + '//' + window.location.hostname;

export const config = {
  production: false,
  baseUrl: baseURL,
  apiBaseUrl: baseURL + "/api",
  apiFileUploadUrl: baseURL + "/api/file/file_upload.php",
  apiImageUploadUrl: baseURL + "/api/file/image_upload.php",
  cookie_token: 'reftoken',
  cookie_id: 'refid'
};
